
import { commonProps } from '../../utils/prismic';
import getHead from '~/utils/get-head';

export default {
  name: 'BuyapowaPush',
  props: commonProps,
  head() {
    const { buyapowaEmbedUrl } = this.$config;

    const scripts = [
      {
        rel: 'preconnect',
        href: buyapowaEmbedUrl,
        crossorigin: true,
      },
      {
        async: true,
        src: buyapowaEmbedUrl,
      },
    ];

    const meta = [
      {
        hid: 'buyapowa',
        property: 'og:url',
        content: 'https://bp.april.fr/iaf/april_raf1/og',
      },
    ];

    return {
      ...getHead(this, scripts, null, {}, meta),
    };
  },
  mounted() {
    const Buyapowa = window.Buyapowa;

    if (Buyapowa) {
      const buyapowaInstance = new Buyapowa(
        { url: 'https://bp.april.fr', market: 'april' },
        'bp_div',
        'bp_error'
      );
      buyapowaInstance.embedReferAFriend({
        campaign: 'april_raf1',
        locale: 'fr',
      });
    }
  },
};
